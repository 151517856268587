import {Checkbox, FormControl, FormHelperText} from "@mui/joy";
import {Controller, useFormContext} from "react-hook-form";

export function FormCheckbox({name, label}) {
  const form = useFormContext();
  const errors = form.formState.errors;

  return (
    <FormControl error={!!errors[name]}>
      <Controller
        name={name}
        control={form.control}
        render={({field: {ref, value, onChange, onBlur}, fieldState: {error}}) => {
          return <Checkbox
            size="sm"
            label={label}
            ref={ref}
            checked={!!value}
            onChange={(e) => onChange(e)}
            onBlur={onBlur}
            color={error != null ? 'danger' : 'neutral'}
          />
        }}
      />
      {errors[name] && <FormHelperText sx={{ mt: 1 }}>{errors[name].message}</FormHelperText>}
    </FormControl>
  )
}