import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit'
import './features/projects/api';
import './features/callTemplates/api';
import './features/calls/api';
import './features/debug/api';
import './features/onboarding/api';
import activeCallReducer from './features/debug/state';
import authReducer, {setUser} from './features/auth/state';
import {baseApi} from "@/utils/api.js";

const logoutListener = createListenerMiddleware();

// Reset all API caches when the user logs out
logoutListener.startListening({
  actionCreator: setUser,
  effect: function (action, listenerApi) {
    if (action.payload == null) {
      listenerApi.dispatch(baseApi.util.resetApiState());
    }
  }
});

export default configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    activeCall: activeCallReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()      
      .concat([
        logoutListener.middleware,
        baseApi.middleware,
      ]),
});
