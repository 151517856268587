import {Controller, useFormContext} from "react-hook-form";
import {FormControl, FormHelperText, FormLabel, Option, Select} from "@mui/joy";

export function FormSelect({name, label, options}) {
  const form = useFormContext();
  const errors = form.formState.errors;

  return (
    <FormControl error={!!errors[name]}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={form.control}
        render={({field: {ref, value, onChange, onBlur}, fieldState: {error}}) => {
          return (
            <Select
              ref={ref}
              value={value}
              onChange={(_, newValue) => onChange(newValue)}
              onBlur={onBlur}
              error={error == null ? 'false' : 'true'}
            >
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          );
        }}
      />
      {errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl>
  );
}