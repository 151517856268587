import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button, Box, Input, Table, Sheet, Skeleton} from '@mui/joy';
import Add from '@mui/icons-material/Add';
import {EditAction} from './EditAction';
import {DeleteAction} from './DeleteAction';
import {ExtraAction} from './ExtraAction';
import SearchIcon from '@mui/icons-material/Search';
import {capitalizeFirstLetter} from '@/utils/strings';
import {ApiError} from "@/components/ApiError.jsx";

export function CrudTable({names, columns, actions}) {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const {isLoading, error, data} = actions.list();

  const rows = data?.data ?? [];
  const filteredRows = rows.filter((row) => columns[0].getter(row).toLowerCase().includes(search.toLowerCase()));

  const hasActionsColumn = actions.delete || (actions.extra && actions.extra.length > 0);
  const columnCount = hasActionsColumn ? columns.length + 1 : columns.length;

  return <>
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: 2,
        py: 2,
      }}
    >
      <Input
        sx={{width: '250px'}}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
        startDecorator={<SearchIcon/>}
      />
      {actions.create && <Link to={actions.create}><Button startDecorator={<Add/>}>Create {capitalizeFirstLetter(names.singular)}</Button></Link>}
    </Box>

    {error ?
      <ApiError error={error}/> :
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 'sm',
        }}
      >

        <Table color="neutral" variant="plain" hoverRow sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}>
          <thead>
          <tr>
            {columns.map((column) => <th key={column.label}>{column.label}</th>)}
            {hasActionsColumn && <th>Actions</th>}
          </tr>
          </thead>
          <tbody>
          {isLoading ? <RowsSkeleton columnCount={columnCount}/> : <>
            {filteredRows.map((row) => (
              <tr onClick={() => navigate(actions.get.replaceAll(':id', row.id))} key={row.id} style={{cursor: 'pointer'}}>
                {columns.map((column) => <td key={column.label} style={tdStyle}>{column.getter(row)}</td>)}
                {hasActionsColumn && <td>
                  {actions.extra && actions.extra.map((action) => <ExtraAction key={action.type} to={`${row.id}/${action.type}`} icon={action.icon}/>)}
                  {actions.edit && actions.edit(row) && <EditAction to={`${row.id}/update`}/>}
                  {actions.delete && <DeleteAction id={row.id} name={columns[0].getter(row)} useDelete={actions.delete}/>}
                </td>}
              </tr>
            ))}
            {rows.length > 0 && filteredRows.length === 0 && <tr>
              <td colSpan={columnCount}>There are no matching {names.plural}</td>
            </tr>}
            {rows.length === 0 && !isLoading && <tr>
              <td colSpan={columnCount}>There are no {names.plural}</td>
            </tr>}
          </>}
          </tbody>
        </Table>
      </Sheet>
    }
  </>;
}

function RowsSkeleton({columnCount}) {
  return [...Array(5)].map((_, index) => <tr key={index}>
    {[...Array(columnCount)].map((_, index) => <td key={index}>
      <Skeleton variant="text"/>
    </td>)}
  </tr>);
}

const tdStyle = {
  maxWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};
