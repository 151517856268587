import {Box, Button, Stack, Typography} from "@mui/joy";
import {ActiveUser} from "@/components/ActiveUser.jsx";
import {SideBySideLayout} from "@/components/layouts/SideBySideLayout.jsx";
import {OnboardingTip} from "@/components/OnboardingTip.jsx";
import {Logo} from "@/components/Logo.jsx";
import {FormInput} from "@/components/forms/FormInput.jsx";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormSelect} from "@/components/forms/FormSelect.jsx";
import {FormMultipleOptions} from "@/components/forms/FormMultipleOptions.jsx";
import {crms, needHelpWith, jobTitles, newProfile, profileSchema} from "@/features/onboarding/schema.js";
import {useCreateProfileMutation} from "@/features/onboarding/api.js";
import {useNavigate} from "react-router-dom";

export function WelcomePage() {
  const navigate = useNavigate();
  const [save, saveResult] = useCreateProfileMutation();

  const form = useForm({
    defaultValues: newProfile(),
    resolver: yupResolver(profileSchema),
  });

  async function onSubmit(data) {
    const result = await save({ data });
    const projectId  = result.data?.data?.project_id;
    navigate(`/p/${projectId}`);
  }

  return (
    <SideBySideLayout left={
      <>
        <Logo/>
        <Box
          component="main"
          sx={{
            my: 'auto',
            py: 2,
            pb: 7,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 540,
            maxWidth: '100%',
            mx: 'auto',
            borderRadius: 'sm',
          }}
        >
          <Stack gap={5}>
            <Stack gap={1}>
              <Typography component="h1" level="h2">Who are you?</Typography>
              <Typography level="body-md">
                Tell us a bit about yourself so we can personalize your experience.
              </Typography>
            </Stack>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Stack gap={3}>
                  <FormSelect name="job_title" label="Your role" options={jobTitles}/>
                  <FormInput name="company_name" label="Company name"/>
                  <FormMultipleOptions name="need_help_with" label="What do you need help with?" options={needHelpWith} />
                  <FormSelect name="crm" label="Which CRM do you use?" options={crms}/>
                </Stack>
                <Button loading={saveResult.isLoading} type="submit" size="lg" fullWidth sx={{mt: 5}}>Continue</Button>
              </form>
            </FormProvider>
          </Stack>
        </Box>
        <Box sx={{py: 2}}>
          <ActiveUser/>
        </Box>
      </>
    } right={
      <OnboardingTip
        title="Spend less time with CRM and make more sales"
        subtitle="Our sales AI assistant will take care of keeping your CRM up to date and craft perfect follow-up emails."
      />
    }/>
  )
}
