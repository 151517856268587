import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { CompanyForm } from '../components/CompanyForm';
import { useParams } from "react-router-dom";
import { useGetCompanyQuery, useUpdateCompanyMutation } from '../api';

export function UpdateCompanyPage() {
  const { projectId, id } = useParams();
  const { isFetching, data } = useGetCompanyQuery({ projectId, id });

  const [saveCompany, saveResult] = useUpdateCompanyMutation();

  async function save(data) {
    await saveCompany({ projectId, id, data });
  }

  return (
    <DrawerLayout title="Update Company">
      {isFetching ? 'Loading...' : <CompanyForm data={data.data} save={save} saveResult={saveResult} />}
    </DrawerLayout>
  );
}

