import { Box } from '@mui/joy';

export function CenteredLayout({ children }) {
  return (
    <Box
      sx={{
        px: 6,
        py: 3,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: '0 auto',
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
}