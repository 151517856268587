import { Typography } from '@mui/joy';

export function Objections({ data }) {
  if (!data || data.length === 0) return <Typography level="body">No objections</Typography>;

  return (    
    <>
      {data.map((objection, index) => (        
        <Typography key={index} level="body" sx={{ my: 1, py: 1, px: 2 }} variant="soft"><strong>{objection.category}</strong><br/> <Typography level="body-sm">{objection.explanation}</Typography></Typography>
      ))}    
    </>
  );
}