import { Box, Stack, Typography, useTheme } from '@mui/joy';
import { RoundNumber } from '@/components/RoundNumber';

export function Agenda({ data, fulfilled }) {
  const theme = useTheme();  
  const fulfilledPoints = fulfilled.map(item => item.point)

  function renderPoint(point, index) {
    const done = fulfilled.includes(index + 1) || (
      point.sub_points.length > 0 && point.sub_points.every((subPoint, subIndex) => fulfilledPoints.includes(index + 1 + '.' + (subIndex + 1)))
    );
    return (
      <Box key={index} sx={{ mx: -3, px: 3, pt: 2, borderTop: `1px dashed ${theme.vars.palette.divider}` }}>
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <RoundNumber number={index + 1} done={done} />
          <Typography level="h5" sx={ done ? { textDecoration: 'line-through', color: 'var(--joy-palette-neutral-400)' } : {}}>{point.title}</Typography>
        </Box>
        <SubPoints index={index} data={point.sub_points} fulfilled={fulfilledPoints} />
      </Box>
    );
  }

  return (
    <Stack gap={2.5}>      
      {data.points.map((point, index) => renderPoint(point, index))}
    </Stack>
  );
}

function SubPoints({ index, data, fulfilled }) {
  function renderSubPoint(subIndex, subPoint, fulfilled) {
    const subPointNumber = index + 1 + '.' + (subIndex + 1);
    const done = fulfilled.includes(subPointNumber);    
    const sx = { position: 'relative', top: '2px' };
    return (
      <Box key={subIndex} sx={{ ml: 4 }}>
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <RoundNumber size="sm" number={subPointNumber} done={done} />
          <Typography level="body-sm" sx={ done ? { textDecoration: 'line-through', color: 'var(--joy-palette-neutral-400)', ...sx } : sx}>{subPoint.text}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Stack gap={1.5} sx={{ mt: data.length > 0 ? 1 : 0 }}>
      {data.map((subPoint, subIndex) => renderSubPoint(subIndex, subPoint, fulfilled))}
    </Stack>
  );
}
