import { useEffect } from 'react';
import { EventSourcePlus } from "event-source-plus";
import {useDispatch, useStore} from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {useGetCallQuery, useGetCallTranscriptQuery, usePatchCallMutation} from '@/features/calls/api';
import { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Button, ButtonGroup, Divider, IconButton, Stack, Box, Card, Typography } from "@mui/joy";
import { CallStatus } from '@/components/features/CallStatus';
import TimesIcon from '@mui/icons-material/Close';
import { usePostWebhookMutation } from "../api";
import { addTranscriptWebhookPayload } from "../webhooks";
import { fulfillAgendaItem, addObjection, set, reset } from "../state";
import { Transcript } from "@/components/features/Transcript";
import { actorToRole } from "@/utils/strings";
import { InputFileUpload } from "@/components/InputFileUpload";
import {baseApi} from "@/utils/api.js";

export function DebugCallTemplateCallPage() {
  const { projectId, callId } = useParams();      
  const { isFetching, data: response } = useGetCallQuery({ projectId, id: callId });    

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!isFetching && response) {      
      dispatch(set(response.data.details));
    }    
  }, [isFetching, response]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    }
  }, []);

  function close() {
    navigate("..");    
  }  

  return (
    <Box>
      <Card>
        <Box sx={{ display: 'flex' }}>
          <Typography level="h4" sx={{ flex: 1 }}>Call simulation #{ callId }</Typography>      
          <IconButton size="sm" variant="plain" onClick={close}><TimesIcon /></IconButton>        
        </Box>
        {isFetching ? 'Loading...' : <CallDetails data={response.data} />}        
      </Card>
    </Box>
  );
}

function CallDetails({ data }) {  
  const dispatch = useDispatch()
  const store = useStore();
  const { projectId } = useParams();    

  const [lastJsonMessage, setLastJsonMessage] = useState(null);
  const [lines, setLines] = useState([]);  
  const { data: response } = useGetCallTranscriptQuery({ projectId, id: data.id }, { refetchOnMountOrArgChange: 3600 });

  const [postWebhook] = usePostWebhookMutation();

  useEffect(() => {    
    if (!response) return;
    setLines(response.data);
  }, [response])

  useEffect(() => {
    const url = import.meta.env.VITE_BACKEND_BASE_URL + '/p/' + projectId + '/calls/' + data.id + '/updates';
    const token = store.getState().auth.user?.accessToken;
    const eventSource = new EventSourcePlus(url, {
      headers: {
        'X-Firebase-Access-Token': token
      }
    });
    const controller = eventSource.listen({
      onMessage(message) {
        setLastJsonMessage(JSON.parse(message.data));
      },
    });
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (lastJsonMessage == null) return;
    if (lastJsonMessage.event_type === 'add_transcript_line') {
      setLines([...lines, lastJsonMessage.line]);
    } else if (lastJsonMessage.event_type === 'end_call') {
      dispatch(baseApi.util.invalidateTags(['Calls', 'Transcripts']));
    } else if (lastJsonMessage.event_type === 'fulfill_agenda_item') {
      dispatch(fulfillAgendaItem(lastJsonMessage.agenda_item));
    } else if (lastJsonMessage.event_type === 'add_objection') {
      dispatch(addObjection(lastJsonMessage.objection));
    }
  }, [lastJsonMessage]);

  function fileUploaded(e) {    
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      let accumulatedTime = 0;

      const lines = text.split('\n').map((line, index) => {
        const match = line.match(/^(.+?) \((Host|Participant)\): (.+)\r?$/);        
        if (!match) return null; // Skip lines that don't match the expected format
        
        const [, participantName, role, text] = match;
        
        // Calculate time values
        const charsPerSecond = 7;
        const duration = text.length / charsPerSecond;
        const startTime = accumulatedTime;
        const endTime = accumulatedTime + duration;
        
        // Update accumulated time for the next line
        accumulatedTime = endTime;

        return {          
          role,
          participantName,
          text,
          startTime: parseFloat(startTime.toFixed(2)),
          endTime: parseFloat(endTime.toFixed(2))
        };
      }).filter(Boolean); // Filter out any null values from lines that didn't match

      (async function () {
        for (const line of lines) {
          const payload = addTranscriptWebhookPayload({ 
            botId: data.debug_bot_id, 
            skipGrouping: true,
            ...line
          });          
          await postWebhook({ data: payload});
          await new Promise(resolve => setTimeout(resolve, 250));
        }
      }());
    };
    reader.readAsText(e.target.files[0]);    
  }

  return <Box>    
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>      
      {data.status === 'active' && <SpeechRecognitionForm data={data} />}
      <Box><CallStatus status={data.status} /></Box>
    </Box>

    {data.status === 'active' && <Box sx={{ mt: 1 }}>
      <InputFileUpload title="Upload transcript" onChange={fileUploaded} />
    </Box>}

    {lines.length > 0 && <Box sx={{ mt: 2 }}>
      <Divider sx ={{ mx: -2, mb: 2 }} />
      <Transcript data={lines} />      
    </Box>}
  </Box>
}

function SpeechRecognitionForm({ data }) {
  const {
    transcript,    
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const { projectId} = useParams();

  const [activeActor, setActiveActor] = useState(null);    
  const [startedListeningAt, setStartedListeningAt] = useState(null);
  const [postWebhook, postWebhookResult] = usePostWebhookMutation();
  const [patchCall, patchCallResult] = usePatchCallMutation();

  function listenToHost() {    
    listenTo({ role: 'Host', participantName: 'John Doe' })
  }

  function listenToParticipant() {      
    listenTo({ role: 'Participant', participantName: 'Olivia Puffington' });    
  }

  function listenTo({ role, participantName }) {
    if (activeActor && activeActor.role === role) {
      stop();    
      return;    
    }
        
    addTranscriptToLines();    
    resetTranscript();
    setActiveActor({ role, participantName });
    setStartedListeningAt(new Date());
    SpeechRecognition.startListening({ continuous: true });    
  }

  function stop() {
    addTranscriptToLines();

    setActiveActor(null);
    SpeechRecognition.stopListening();
    setStartedListeningAt(null);
    resetTranscript();
  }

  function addTranscriptToLines() {
    if (activeActor != null && transcript != '') {      
      const payload = addTranscriptWebhookPayload({ 
        botId: data.debug_bot_id, 
        role: activeActor.role, 
        participantName: activeActor.participantName, 
        text: transcript,
        startTime: (startedListeningAt.getTime() - new Date(data.created).getTime()) / 1000,
        endTime: (new Date().getTime() - new Date(data.created).getTime()) / 1000
      });
      postWebhook({ data: payload });      
    }
  }

  function isHostActive() {
    return activeActor && activeActor.role === 'Host';
  }

  function isParticipantActive() {
    return activeActor && activeActor.role === 'Participant';
  }

  async function endCall() {
    await patchCall({ projectId: projectId, id: data.id, data: { status: 'finished' } });
  }

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>      
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack direction="row" gap={1} sx={{ flex: 1 }}>
          <ButtonGroup size="sm">
            <Button variant={isHostActive() ? "solid" : "outlined"} onClick={listenToHost}>Listen to host</Button>
            <Button variant={isParticipantActive() ? "solid" : "outlined"} onClick={listenToParticipant}>Listen to participant</Button>                        
          </ButtonGroup>
          <Button size="sm" variant="solid" color="danger" loading={postWebhookResult.isLoading} onClick={endCall}>End call</Button>              
        </Stack>        
      </Box>
      {activeActor && transcript != '' && <Box sx={{ mt: 2 }}><strong>{activeActor.participantName} ({actorToRole(activeActor.role)}):</strong> {transcript}</Box>}                  
    </div>
  );
}
