import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "@/features/auth/firebase.js";
import {Navigate, Outlet, useLocation} from "react-router-dom";


export function RequireAuth() {
  const location = useLocation();
  const [user] = useAuthState(auth);
  if (user === null) {
    const to = "/sign-in?redirect=" + encodeURIComponent(location.pathname);
    return <Navigate to={to} />
  } else {
    return <Outlet />;
  }
}

