import { useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Chip, FormControl, FormLabel, Input, List, ListItem, Divider, Stack, Typography, Link, useTheme } from '@mui/joy';
import { ContentGenerationInProgress } from '@/components/ContentGenerationInProgress';
import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { useParams } from "react-router-dom";
import { DeleteAction } from '@/components/DeleteAction';
import { EditAction } from '@/components/EditAction';
import { Agenda } from '@/components/features/Agenda';
import { Transcript } from '@/components/features/Transcript';
import { Objections } from '@/components/features/Objections';
import { CallStatus } from '@/components/features/CallStatus';
import { ExtractedData } from '@/components/features/ExtractedData';
import { useDeleteCallMutation, usePatchCallMutation, useGetCallWithTemplateQuery, useGetCallTranscriptQuery, useGetLatestDataExtractionQuery, useGetLatestSummaryQuery } from '../api';
import { useCrudProjectMutation } from '@/hooks/useCrudProjectMutation';
import { formatDate } from '@/utils/columns';
import AddIcCallRoundedIcon from '@mui/icons-material/AddIcCallRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { actorToRole } from '@/utils/strings';

export function CallPage() {
  const { projectId, id } = useParams();
  
  // TODO: Use socket instead of polling
  const { isLoading, data: response } = useGetCallWithTemplateQuery({ projectId, id }, {
    pollingInterval: 2000,
    skipPollingIfUnfocused: true,    
  });

  const useDelete = useCrudProjectMutation(projectId, useDeleteCallMutation);

  return (
    <DrawerLayout wide={!isLoading && response.data.status === 'finished'} title="Call" headerButtons={
      !isLoading && <>
        {response.data.status === 'pending' && <EditAction to={`/p/${projectId}/calls/${id}/update`} />}
        <DeleteAction id={id} name={response.data.meeting_url} redirectOnSuccess={true} useDelete={useDelete} />
      </>
    }>
      {isLoading ? 'Loading...' : <Call data={response.data} />}
    </DrawerLayout>
  );
}

function Call({ data }) {
  const theme = useTheme();  

  const { projectId, id } = useParams();
  const [patchCall, patchResult] = usePatchCallMutation();

  function inviteBot() {
    patchCall({ id, projectId, data: { status: 'active' } });
  }

  const finished = data.status === 'finished';  

  return (
    <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
      <Box sx={{ gridColumn: finished ? 'span 6' : 'span 12'}}>        
        <div>
          <Stack sx={{ mt: 1, pb: 1 }} gap={2}>
            {!finished &&
              <FormControl>
                <FormLabel>Meeting URL</FormLabel>
                <Input value={data.meeting_url} disabled />
              </FormControl>
            }

            <FormControl>
              <FormLabel>Call type</FormLabel>
              <Input value={data.template.name} disabled />
            </FormControl>

            <FormControl>
              <FormLabel>Company</FormLabel>
              <Input value={data.company?.name ?? 'None'} disabled />
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <CallStatus size="md" status={data.status} />
              <Chip variant="outlined">Created: {formatDate(data.created)}</Chip>          
            </Box>        
            <Errors data={data.errors} />
          </Stack>
          <Divider sx={{ mx: -3, my: 2 }} />

          {!finished ? <>
            <Typography level="h4" sx={{ mb: 2 }}>Agenda</Typography>
            <Agenda data={data.template.agenda} fulfilled={data.details.fulfilled_agenda_items} />
          </> : <>
            <Typography level="h4" sx={{ mb: 2 }}>Agenda Compliance</Typography>
            <AgendaCompliance data={data.template.agenda} fulfilled={data.details.fulfilled_agenda_items} />

            <Divider sx={{ mx: -3, my: 2, mt: 3 }} />

            <SummaryContainer />                              
          </>}          

          <Divider sx={{ mx: -3, my: 2, mt: 3 }} />

          <Typography level="h4" sx={{ mb: 2 }}>Objections</Typography>
          <Objections data={data.details.objections} />      

          {data.status === 'pending' && <>
            <Divider sx={{ mx: -3, my: 3 }} />
            <Button loading={patchResult.isFetching} startDecorator={<AddIcCallRoundedIcon />} onClick={inviteBot}>Invite bot</Button>
          </>}
        </div>    
      </Box>      
      {finished && <Box sx={{ gridColumn: 'span 6', borderLeft: `1px solid ${theme.vars.palette.divider}`, ml: 1, mr: -3, my: -2, mb: -3 }}>        
        <FinishedCall data={data} />
      </Box>}
    </Box>      
  );
}

function AgendaCompliance({ data, fulfilled }) {  
  const [showDetails, setShowDetails] = useState(false);

  let { totalCount, fulfilledCount } = useMemo(() => {          
    let totalCount = 0, fulfilledCount = 0;    

    const fulfilledSet = new Set(fulfilled.map(f => f.point));    

    for (let [pointIndex, point] of data.points.entries()) {          
      totalCount += point.sub_points.length + 1;
 
      let hasAll = true;
      for (let [subPointIndex, subPoint] of point.sub_points.entries()) {        
        var subPointNumber = `${pointIndex + 1}.${subPointIndex + 1}`;
        if (fulfilledSet.has(subPointNumber)) {
          fulfilledCount++;
        } else {
          hasAll = false;
        }
      }      

      const hasParent = point.sub_points.length === 0 && fulfilledSet.has(pointIndex + 1);
      if (hasParent || hasAll) {
        fulfilledCount++;
      }
    }  

    return { totalCount, fulfilledCount };
  }, [data, fulfilled]);

  const value = Math.round((fulfilledCount / totalCount) * 100);    
  const color = value > 66 ? 'success' : value > 33 ? 'warning' : 'danger';

  return (
    <>
      <Stack gap={3} direction="row" sx={{ alignItems: 'center' }}>
        <CircularProgress variant="soft" color={color} size="lg" determinate value={value}>
          <Typography>{value}%</Typography>
        </CircularProgress>
        <Box>
          <Typography level="title-md">
            {fulfilledCount} of {totalCount} agenda points fulfilled
          </Typography>
          <Link color="neutral" level="body-sm" onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? 'Hide details' : 'Show details'}          
          </Link>
        </Box>
      </Stack>
      {showDetails && <Box sx={{ mt: 2 }}>
        <Agenda data={data} fulfilled={fulfilled} />
      </Box>}
    </>
  );
}

function SummaryContainer() {
  const { projectId, id } = useParams();
  const summary = useGetLatestSummaryQuery({ projectId, id }, {
    pollingInterval: 2000,
    skipPollingIfUnfocused: true,    
  });
  return <>
    <Typography level="h4" sx={{ mb: 2 }}>Summary</Typography>
    {summary.isLoading ? 'Loading...' : 
      summary.data ? 
        <Summary data={summary.data.data.summary}  /> : 
        <ContentGenerationInProgress title="Summary generation" /> }    
  </>;  
}

function Summary({ data }) {
  const theme = useTheme();
  const header1 = { fontWeight: 'bold', textTransform: 'uppercase' };
  const header2 = { fontWeight: '600' };
  const point = { 
    borderRadius: '2px', 
    borderLeft: `2px solid ${theme.vars.palette.background.level3}`, 
    backgroundColor: theme.vars.palette.background.level1, 
    px: 1, 
    py: 0.5 
  };
  if (data.key_takeaways == null) return <Typography level="body-md">No summary was generated</Typography>;
  return (
    <Stack gap={2}>
      <Box>
        <Typography level="body-sm" sx={header1}>Meeting purpose</Typography>
        <Typography level="body-sm">{data.meeting_purpose}</Typography>
      </Box>
      <Box>
        <Typography level="body-sm" sx={header1}>Key takeaways</Typography>
        {data.key_takeaways.length === 0 && <Typography level="body-sm">No key takeaways</Typography>}
        <List size="sm" sx={{ mb: -0.5 }} marker="disc">
          {data.key_takeaways.map((takeaway, index) => (
            <ListItem key={index}>
              <Typography level="body-sm">{takeaway}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <Typography level="body-sm" sx={header1}>Topics discussed</Typography>
        <Stack gap={1} sx={{mt: 1}}>
          {data.topics_discussed.map((topic, index) => (
            <Stack gap={0.5} sx={point} key={index}>
              <Typography level="body-sm" sx={header2}>{topic.name}</Typography>                           
                <List size="sm" marker="disc">              
                {topic.topic_points.map((point, index) => (
                  <ListItem key={index} sx={{ mt: -0.75, mb: -0.25 }}>
                    <Typography level="body-sm">{point}</Typography>
                  </ListItem>
                ))}              
                </List>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Box>
        <Typography level="body-sm" sx={header1}>Next steps</Typography>
        {data.next_steps.length === 0 ? <Typography level="body-sm">No next steps</Typography> :
          <Stack gap={1} sx={{mt: 1}}>          
            {data.next_steps.map((step, index) => (
              <Typography sx={{ py: 1, px: 2 }} level="body" variant="soft" color={ step.person === 'Host' ? 'warning': 'primary'} key={index}>
                {step.person && <Box><strong>{actorToRole(step.person)}</strong></Box>}
                <Typography level="body-sm">{step.activity}</Typography>
                {step.deadline && <Box sx={{ mt: 1 }}>
                  <Stack direction="row" gap={0.75} sx={{ alignItems: 'center' }}>
                    <AccessTimeIcon />
                    <Typography level="title-sm">{step.deadline}</Typography>
                  </Stack>
                </Box>}
              </Typography>
            ))}
          </Stack>
        }
      </Box>      
    </Stack>
  )
}

function FinishedCall({ data }) {
  const { projectId, id } = useParams();  
  const transcript = useGetCallTranscriptQuery({ projectId, id });

  return (
    <Stack gap={2} sx={{ p: 3, pt: 2.5 }}>      
      <ExtractedDataContainer schema={data.template.data_extraction} />      
      <Divider sx={{ mx: -3, mt: 1, mb: 0.5 }} />
      <Typography level="h4">Transcript</Typography>
      {transcript.isLoading ? 'Loading...' : <Transcript data={transcript.data.data} />}
    </Stack>
  );
}

function ExtractedDataContainer({ schema }) {
  const { projectId, id } = useParams();

  // TODO: Use socket instead of polling
  const extractedData = useGetLatestDataExtractionQuery({ projectId, id }, {
    pollingInterval: 2000,
    skipPollingIfUnfocused: true,    
  });

  return <>
    <Typography level="h4">Extracted Data</Typography>
    {extractedData.isLoading ? 'Loading...' : 
      extractedData.data ? 
        <ExtractedData data={extractedData.data.data} schema={schema} /> : 
        <ContentGenerationInProgress title="Data Extraction" /> }
  </>;
}

function Errors({ data }) {
  if (!data || data.length === 0) return null;

  return (
    <Alert color="danger" variant="soft">
      <div>        
        {data.map((error, index) => (
          <Typography key={index} level="body-sm" color="danger">{error}</Typography>
        ))}
      </div>
    </Alert>
  );
}