import {useLocation, Outlet, Navigate} from "react-router-dom";
import {useIdToken} from "react-firebase-hooks/auth";
import {auth} from "@/features/auth/firebase.js";
import {FullScreenLoader} from "@/components/FullScreenLoader.jsx";
import {useDispatch} from "react-redux";
import {setUser} from "@/features/auth/state.js";

export function AuthBase() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [user, loading, error] = useIdToken(auth);

  if (loading) return <FullScreenLoader />;

  // TODO: Make this a bit more user friendly
  if (error) return <div>Error: {error}</div>;

  const userData = user === null ? null : { accessToken: user.accessToken };
  dispatch(setUser(userData));

  if (location.pathname === '/') {
    const path = (user) ? '/default-project' : '/sign-in';
    return <Navigate to={path} replace={true} />;
  }

  return <Outlet />;
}
