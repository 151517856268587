import {Controller, useFormContext} from "react-hook-form";
import {Button, ButtonGroup, FormControl, FormHelperText, FormLabel, ToggleButtonGroup} from "@mui/joy";

export function FormMultipleOptions({name, label, options}) {
  const form = useFormContext();
  const errors = form.formState.errors;

  return (
    <FormControl error={!!errors[name]}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={form.control}
        render={({field: {ref, value, onChange, onBlur}, fieldState: {error}}) => {
          function isActive(actual) {
            return value.indexOf(actual) >= 0;
          }

          return (
            <ToggleButtonGroup ref={ref} value={value} onChange={(_, newValue) => onChange(newValue)} color="neutral">
              {options.map((option) => (
                <Button
                  key={option.value}
                  value={option.value}
                  variant="outlined"
                  sx={{py: 1.5, lineHeight: 'var(--joy-lineHeight-xs)', textWrap: 'balance', width: `${(100 / options.length)}%`}}
                >
                  {option.label}
                </Button>
              ))}
            </ToggleButtonGroup>
          );
        }}
      />
      {errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl>
  );
}