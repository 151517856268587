import {baseApi} from "@/utils/api.js";

const debugApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postWebhook: builder.mutation({
      query: ({ data }) => ({
        url: `/webhook?secret=${data.data.bot_id}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {  
  usePostWebhookMutation,
} = debugApi;
