import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { CallTemplateForm } from '../components/CallTemplateForm';
import { useParams } from "react-router-dom";
import { useCreateCallTemplateMutation } from '../api';
import { newCallTemplate } from '../schema';

export function CreateCallTemplatePage() {  
  const { projectId } = useParams();  
  
  const data = newCallTemplate();
  const [saveCallTemplate, saveResult] = useCreateCallTemplateMutation();

  async function save(data) {
    await saveCallTemplate({ projectId, data });
  }

  return (
    <DrawerLayout title="Create Playbook">      
      <CallTemplateForm data={data} save={save} saveResult={saveResult} />      
    </DrawerLayout>
  );
}
