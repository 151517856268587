
import {Box, Chip, Stack, Typography, useTheme} from '@mui/joy';

export function ExtractedData({ data, schema }) {
  if (Object.keys(data.data).length === 0) {
    return <Typography level="body-md">No data was extracted</Typography>
  }
  return (
    <ExtractedParameters data={data.data} schema={schema} level={0} />
  );
}

function ExtractedParameters({ data, schema, level }) {
  const theme = useTheme();
  return (
    <Stack gap={level == 0 ? 2 : 1}>
      {schema.parameters.map((parameterSchema, index) => <Box key={index} sx={ level == 0 ? {} : { borderRadius: '2px', borderLeft: `2px solid ${theme.vars.palette.background.level3}`, backgroundColor: theme.vars.palette.background.level1, px: 1, py: 0.5 }}>
        <Typography level="body-sm" sx={{ fontWeight: level == 0 ? 'bold' : '600', textTransform: level == 0 ? 'uppercase' : 'capitalize' }}>{parameterSchema.name.replace('_', ' ')}</Typography>
        <ExtractedParameter data={data[parameterSchema.name] ?? ''} schema={parameterSchema} level={level} />
      </Box>)}
    </Stack>
  );
}

function ExtractedParameter({ data, schema, level }) {
  switch (schema.type) {
    case 'string':
      return <Typography level="body-sm">{isPrimitive(data) ? data : <InvalidData />}</Typography>
    case 'enum':
      return <Typography level="body-sm">{isPrimitive(data) ? data : <InvalidData />}</Typography>
    case 'number':
      return <Typography level="body-sm">{isPrimitive(data) ? data : <InvalidData />}</Typography>
    case 'boolean':
      return <Typography level="body-sm">{isPrimitive(data) ? (data ? 'Yes' : 'No') : <InvalidData />}</Typography>
    case 'array':
      return isArray(data) ? <ExtractedArray data={data} schema={schema} level={level} /> : <InvalidData />
    case 'object':
      return isObject(data) ? <Box sx={{ mt: 1 }}><ExtractedParameters data={data} schema={schema.value_object} level={level + 1} /></Box> : <InvalidData />
    default:
      return null
  }
}

function ExtractedArray({ data, schema, level }) {
  const theme = useTheme();
  return (
    <Box sx={{ mt: 1 }}>
      <Stack gap={1}>
        {data.map((item, index) => <Box key={index} sx={{ borderRadius: '2px', borderLeft: `2px solid ${theme.vars.palette.background.level3}`, backgroundColor: theme.vars.palette.background.level1, px: 1, py: 0.5 }}>
          <ExtractedParameter data={item} schema={schema.value_array.item} level={level + 1} />
        </Box>)}
      </Stack>
    </Box>
  );
}

function isPrimitive(value) {
  if (value === undefined || value === null) {
    return true;
  }
  return ['string', 'number', 'boolean'].includes(typeof value);
}

function isObject(value) {
  return typeof value === 'object' && !Array.isArray(value);
}

function isArray(value) {
  return Array.isArray(value);
}

function InvalidData() {
  return <Chip variant="soft" color="danger" size="sm">Invalid data type</Chip>
}
