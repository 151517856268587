import { createSlice } from '@reduxjs/toolkit'

const initialState = { fulfilled_agenda_items: [], objections: [] };

const activeCallSlice = createSlice({
  name: 'activeCall',
  initialState,
  reducers: {
    fulfillAgendaItem(state, action) {
      state.fulfilled_agenda_items.push(action.payload)
    },
    addObjection(state, action) {
      state.objections.push(action.payload)
    },
    reset(state) {
      state.fulfilled_agenda_items = [];
      state.objections = [];
    },
    set(state, action) {
      state.fulfilled_agenda_items = action.payload.fulfilled_agenda_items;
      state.objections = action.payload.objections;
    },
  },
})

export const { fulfillAgendaItem, addObjection, reset, set } = activeCallSlice.actions
export default activeCallSlice.reducer
