import {Box} from '@mui/joy';
import {SideBySideLayout} from "@/components/layouts/SideBySideLayout.jsx";
import {Logo} from "@/components/Logo.jsx";
import {OnboardingTip} from "@/components/OnboardingTip.jsx";

export function AuthLayout({children}) {
  return <SideBySideLayout left={
      <>
        <Logo />
        <Box
          component="main"
          sx={{
            my: 'auto',
            py: 2,
            pb: 7,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 400,
            maxWidth: '100%',
            mx: 'auto',
            borderRadius: 'sm',
          }}
        >
          {children}
        </Box>
      </>
    }
    right={
      <OnboardingTip
        title="Spend less time with CRM and make more sales"
        subtitle="Our sales AI assistant will take care of keeping your CRM up to date and craft perfect follow-up emails."
      />
    }
  />;
}