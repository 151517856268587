import { Box } from '@mui/joy';
import { Sidebar } from '../Sidebar.jsx';

export function ProjectLayout({ children }) {
  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      <Sidebar />      
      <Box
          component="main"          
          sx={{                        
            flex: 1,
            display: 'flex',
            flexDirection: 'column',            
            height: '100dvh',
            gap: 1,
            overflow: 'auto',
            p: 4,
            pt: 3,
          }}
        >
          <Box sx={{
            maxWidth: '1400px',
            margin: '0 auto',
            minWidth: 0,
            width: '100%',
          }}>
            { children }
          </Box>
        </Box>
    </Box>
  );
}