import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { CallForm } from '../components/CallForm';
import { useParams } from "react-router-dom";
import { useCreateCallMutation } from '../api';
import { newCall } from '../schema';

export function CreateCallPage() {  
  const { projectId } = useParams();  
  
  const data = newCall();
  const [saveCall, saveResult] = useCreateCallMutation();

  async function save(data) {
    await saveCall({ projectId, data });
  }

  return (
    <DrawerLayout title="Create Call">      
      <CallForm data={data} save={save} saveResult={saveResult} />
    </DrawerLayout>
  );
}
