import {  } from 'firebase/auth';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import {auth} from "@/features/auth/firebase.js";


function fetchBase() {
  return fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
      const user = auth.currentUser;
      if (user == null) {
        return headers;
      }
      const token = await user.getIdToken();
      if (token) {
        headers.set('X-Firebase-Access-Token', token);
      }
      return headers;
    }
  });
}

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBase(),
  endpoints: () => ({}),
});

export function extendCrudApiByProject({ entityNameSingular, entityNamePlural, entityPath, extraEndpoints, extraTags }) {
  if (!extraEndpoints) {
    extraEndpoints = () => ({});
  }

  if (!extraTags) {
    extraTags = [];
  }

  const apiWithTag = baseApi.enhanceEndpoints({addTagTypes: [entityNamePlural, ...extraTags]})
  return apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
      [`get${entityNamePlural}`]: builder.query({
        query: ({ projectId, ...filterBy }) => ({
          url: `/p/${projectId}/${entityPath}/`,
          method: 'GET',
          params: filterBy,
        }),
        providesTags: (result, error, arg) => result ? [
          ...result.data.map(({ id }) => ({ type: entityNamePlural, id, projectId: arg.projectId })),
          { type: entityNamePlural, id: 'LIST', projectId: arg.projectId }
        ] : [{ type: entityNamePlural, id: 'LIST', projectId: arg.projectId }],
      }),
      [`get${entityNameSingular}`]: builder.query({
        query: ({ projectId, id }) => ({
          url: `/p/${projectId}/${entityPath}/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, arg) => [{ type: entityNamePlural, id: arg.id, projectId: arg.projectId }],
      }),
      [`update${entityNameSingular}`]: builder.mutation({
        query: ({ projectId, id, data }) => ({
          url: `/p/${projectId}/${entityPath}/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [{ type: entityNamePlural, id: arg.id, projectId: arg.projectId }],
      }),
      [`patch${entityNameSingular}`]: builder.mutation({
        query: ({ projectId, id, data }) => ({
          url: `/p/${projectId}/${entityPath}/${id}`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [{ type: entityNamePlural, id: arg.id, projectId: arg.projectId }],
      }),
      [`delete${entityNameSingular}`]: builder.mutation({
        query: ({ projectId, id }) => ({
          url: `/p/${projectId}/${entityPath}/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [
          { type: entityNamePlural, id: arg.id, projectId: arg.projectId },
          { type: entityNamePlural, id: 'LIST', projectId: arg.projectId }
        ],
      }),
      [`create${entityNameSingular}`]: builder.mutation({
        query: ({ projectId, data }) => ({
          url: `/p/${projectId}/${entityPath}/`,
          method: 'POST',
          body: data,
        }),
        invalidatesTags: (result, error, arg) => [{ type: entityNamePlural, id: 'LIST', projectId: arg.projectId }],
      }),
      ...extraEndpoints(builder),

    }),
    overrideExisting: false,
  });
}
