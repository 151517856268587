import { Box, Stack, Typography } from '@mui/joy';
import { secondsToMinuteSeconds } from '@/utils/datetime';
import { actorToRole } from '@/utils/strings';

export function Transcript({ data }) {
  if (data.length === 0) {
    return <Typography level="body-md">Nothing to transcribe</Typography>;
  }
  return (
    <Stack gap={2}>
      {data.map((line, _) => <Box key={line.id}>        
        <Box sx={{ float: 'left', width: '40px' }}>
          <Typography level="body-xs" sx={{ pt: '2px' }}>{secondsToMinuteSeconds(line.start_time)}</Typography>
        </Box>
        <Typography level="body-sm" sx={{ ml: '40px' }}><strong>{line.participant_name} ({actorToRole(line.role)}):</strong> {line.text}</Typography>        
      </Box>)}
    </Stack>
  );
}
