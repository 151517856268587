import { Box, IconButton } from "@mui/joy";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export function RemoveFormElementButton({ size, onClick }) {
  return (
    <Box>
      <IconButton variant="outlined" size={size} onClick={onClick}>
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
  );
}
