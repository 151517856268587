import { FormLabel, FormControl, FormHelperText, Input, Stack } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from "react-hook-form";
import { FormButtons } from '@/components/FormButtons';
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams } from "react-router-dom";
import {companySchema} from "@/features/companies/schema.js";

export function CompanyForm({ data, save, saveResult }) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(companySchema)
  });

  const errors = form.formState.errors;

  async function onSubmit(data) {
    await save(data);
    navigate(`/p/${projectId}/companies`);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            pt: 2,
            pb: 3,
          }}
        >
          <Stack spacing={2}>
            <FormControl error={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...form.register("name")} placeholder="Name" />
              {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>
        <FormButtons isSaving={saveResult.isLoading} />
      </form >
    </FormProvider>
  );
}

