import * as yup from 'yup';

export const MIN_POINTS = 1;
export const MAX_POINTS = 10;

export const MIN_SUB_POINTS = 0;
export const MAX_SUB_POINTS = 5;

export const MIN_EXAMPLES = 0;
export const MAX_EXAMPLES = 5;

export const MIN_PARAMETERS = 0;
export const MAX_PARAMETERS = 25;

export const MIN_CHOICES = 1;
export const MAX_CHOICES = 10;

// Adjusting this value will require changes to the rendering code in ExtractedData component
export const MAX_NESTED_LEVELS = 2;

export const subPointTextSchema = yup.object({
  text: yup.string().required('Sub point title is required'),
});

export const exampleTextSchema = yup.object({
  text: yup.string().required('Example is required'),
});

export const pointSchema = yup.object({
  title: yup.string().required('Agenda point title is required'),
  examples: yup.array().min(MIN_EXAMPLES).max(MAX_EXAMPLES).of(exampleTextSchema),
  sub_points: yup.array().min(MIN_SUB_POINTS).max(MAX_SUB_POINTS).of(subPointTextSchema),
});

export const valueSchema = yup.object({
  type: yup.string().required('Type is required').oneOf(['string', 'number', 'boolean', 'enum', 'array', 'object']),

  value_string: yup.object({}).when('type', {
    is: 'string',
    then: () => yup.object().required('Parameter value is required'),
    otherwise: () => yup.object().notRequired()
  }),

  value_number: yup.object({}).when('type', {
    is: 'number',
    then: () => yup.object().required('Parameter value is required'),
    otherwise: () => yup.object().notRequired()
  }),

  value_boolean: yup.object({}).when('type', {
    is: 'boolean',
    then: () => yup.object().required('Parameter value is required'),
    otherwise: () => yup.object().notRequired()
  }),

  value_enum: yup.object({
    choices: yup.array().min(1).of(yup.object({
      value: yup.string().required('Choice value is required'),
      description: yup.string(),
    }))
  }).when('type', {
    is: 'enum',
    then: () => yup.object().required('Parameter value is required'),
    otherwise: () => yup.object().notRequired()
  }),

  value_array: yup.object({
    item: yup.lazy(() => valueSchema)
  }).when('type', {
    is: 'array',
    then: () => yup.object().required('Parameter value is required'),
    otherwise: () => yup.object().notRequired()
  }),

  value_object: yup.object({
    parameters: yup.lazy(() => parametersSchema)
  }).when('type', {
    is: 'object',
    then: () => yup.object().required('Parameter value is required'),
    otherwise: () => yup.object().notRequired()
  })
})

export const parameterSchema = valueSchema.concat(yup.object({
  name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
}));

export const parametersSchema = yup.array().required("Parameters are required").min(MIN_PARAMETERS).max(MAX_PARAMETERS).of(parameterSchema);

export const callTemplateSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  agenda: yup.object({
    points: yup.array().min(MIN_POINTS).max(MAX_POINTS).of(pointSchema)
  }),
  data_extraction: yup.object({    
    parameters: parametersSchema,  
  }),
});

export function newCallTemplate() {
  return {
    title: '',
    agenda: {
      points: [newPoint()],
    },
    data_extraction: {      
      parameters: []
    }
  }
}

export function newPoint() {
  return {
    title: '',
    sub_points: [],
    examples: []
  }
}

export function newPolymorphicValue() {
  return {
    type: 'string',
    value_string: newValue('string'),
    value_number: null,
    value_boolean: null,
    value_enum: null,
    value_array: null,
    value_object: null,
  }
}

export function newParameter() {
  return {
    name: '',
    description: '',
    ...newPolymorphicValue(),
  }
}

export function newValue(type) {
  switch (type) {
    case 'string':
      return {};
    case 'number':
      return {};
    case 'boolean':
      return {};
    case 'enum':
      return { choices: [] };
    case 'array':
      return { item: newPolymorphicValue() };
    case 'object':
      return { parameters: [] };
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

export function newChoice() {
  return {
    value: '',
    description: '',
  };
}
