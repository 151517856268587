import {Box, Typography} from "@mui/joy";

export function OnboardingTip({title, subtitle}) {
  return (<Box
      component="main"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        width: 580,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        textAlign: 'center',
      }}
    >
      <Typography
        level="h1"
        sx={{
          color: '#fff',
          fontWeight: 'xl',
          fontSize: 'clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)',
        }}
      >
        {title}
      </Typography>

      <Typography
        textColor="text.secondary"
        sx={{fontSize: 'xl', lineHeight: 'xl', fontWeight: 300, color: '#fff'}}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}