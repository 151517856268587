import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { IconButton } from '@mui/joy';
import { AlertDialogModal } from './AlertDialogModal';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {statusCodeToMessage} from "@/utils/errors.js";

export function DeleteAction({ id, name, redirectOnSuccess, useDelete }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [performDelete] = useDelete();

  function handleDelete(e) {
    e.stopPropagation();
    setOpen(true);
  }

  async function onConfirm() {
    const result = await performDelete({id});
    if (result.error) {
      throw new Error(statusCodeToMessage(result.error.status));
    } else if (redirectOnSuccess) {
      navigate('..');
    }
  }

  return <>
    <IconButton size="sm" variant="plain" onClick={handleDelete}><DeleteOutlineRoundedIcon /></IconButton>
    <AlertDialogModal open={open} setOpen={setOpen} props={{
      title: `Delete ${name}?`,
      message: `Are you sure you want to delete ${name}?`,
      confirmActionText: 'Delete',
      confirmActionColor: 'danger',
      onConfirm,
    }} />
  </>
}
