import {extendCrudApiByProject} from '@/utils/api.js';
import { useGetCallTemplateQuery } from '../callTemplates/api';
import {useGetCompanyQuery} from "@/features/companies/api.js";

const callsApi = extendCrudApiByProject({
  entityNameSingular: 'Call',
  entityNamePlural: 'Calls',
  entityPath: 'calls',
  extraEndpoints: (builder) => ({
    getCallTranscript: builder.query({
      query: ({ projectId, id }) => ({
        url: `/p/${projectId}/calls/${id}/transcript`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'Transcripts', id: arg.id, projectId: arg.projectId }],
    }),

    getLatestDataExtraction: builder.query({
      query: ({ projectId, id }) => ({
        url: `/p/${projectId}/calls/${id}/data-extractions/latest`,
        method: 'GET',
      }),
    }),

    getLatestSummary: builder.query({
      query: ({ projectId, id }) => ({
        url: `/p/${projectId}/calls/${id}/summaries/latest`,
        method: 'GET',
      }),
    }),
  })
});

export const {
  useGetCallsQuery,
  useGetCallQuery,
  useGetCallTranscriptQuery,
  useGetLatestDataExtractionQuery,
  useGetLatestSummaryQuery,
  usePatchCallMutation,
  useDeleteCallMutation,
  useCreateCallMutation,
} = callsApi;

export function useGetCallWithTemplateQuery({ projectId, id }, settings) {
  const { data: call, ...restCall } = useGetCallQuery({ projectId, id }, settings);
  const { data: template, ...restTemplate } = useGetCallTemplateQuery({ projectId, id: call?.data?.template_id }, { skip: call == null });
  const { data: company, ...restCompany } = useGetCompanyQuery({ projectId, id: call?.data?.company_id }, { skip: call == null || call?.data?.company_id == null });

  return {
    isLoading: restCall.isLoading || restTemplate.isLoading || restCompany.isLoading,
    isFetching: restCall.isFetching || restTemplate.isFetching || restCompany.isFetching,
    error: restCall.error || restTemplate.error || restCompany.error,
    data: (call && template && (call.data.company_id == null || company)) ? { data: { ...call.data, template: template.data, company: company?.data } } : undefined,
  };
}
