import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { CallForm } from '../components/CallForm';
import { useParams } from "react-router-dom";
import { useGetCallWithTemplateQuery, usePatchCallMutation } from '../api';

export function UpdateCallPage() {  
  const { projectId, id } = useParams();
  const { isFetching, data: response } = useGetCallWithTemplateQuery({ projectId, id });    

  const [saveCall, saveResult] = usePatchCallMutation();

  async function save(data) {
    await saveCall({ projectId, id, data });
  }

  const mappedData = response == null ? null : convertData(response.data);

  return (
    <DrawerLayout title="Update Call">            
      {isFetching ? 'Loading...' : <CallForm data={mappedData} save={save} saveResult={saveResult} />}
    </DrawerLayout>
  );
}

function convertData(data) {
  return {
    meeting_url: data.meeting_url,
    template_id: data.template.id,
    company_id: data.company_id ?? '',
  };
}
