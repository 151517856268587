import { Box, FormControl, FormHelperText, Input, Divider, Stack, Card, Select, Typography, Option, Button } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { RemoveFormElementButton } from '@/components/RemoveFormElementButton';
import {newParameter, newValue, MIN_PARAMETERS, MAX_PARAMETERS, MIN_CHOICES, MAX_CHOICES, MAX_NESTED_LEVELS, newChoice} from '../schema';

export function DataExtractionForm() {  
  return (
    <Stack gap={1}>    
      <ParametersForm path="data_extraction.parameters" size="md" level={0} />
    </Stack>
  );  
}

function ParametersForm({ path, size, level }) {
  const { fields: parameters, append, remove } = useFieldArray({ name: path });  

  return <>
    {parameters.map((field, index) => <Box key={index}>
      <Divider sx={{ mx: size == 'sm' ? -1.25 : -2, mb: size == 'sm' ? 1.5 : 2 }} />
      <Box sx={{ display: 'flex', gap: size == 'sm' ? 1 : 2 }}>        
        <ParameterForm key={field.id} path={`${path}.${index}`} size={size} level={level} />
        {parameters.length > MIN_PARAMETERS && <RemoveFormElementButton size={size} onClick={() => remove(index)} />}
      </Box>
    </Box>)}
    {parameters.length < MAX_PARAMETERS && <>
      <Divider sx={{ mx: size == 'sm' ? -1.25 : -2, mb: size == 'sm' ? 0.5 : 1 }} />
      <Button startDecorator={<Add />} size={size} variant="outlined" onClick={() => append(newParameter())}>Add Field</Button>
    </>} 
  </>;
}

function ParameterForm({ path, size, level }) {
  const form = useFormContext();
  const nameErrors = form.getFieldState(`${path}.name`).error;
  const descriptionErrors = form.getFieldState(`${path}.description`).error;

  const typeValue = form.watch(`${path}.type`);

  return (
    <Stack spacing={1} sx={{ width: '100%', mb: size == 'sm' ? 0.5 : 1 }}>
      <FormControl error={!!nameErrors} size={size}>
        <Input {...form.register(`${path}.name`)} placeholder="Field Name" endDecorator={
          <>
            <Divider orientation="vertical" />
            <DataTypeSelect path={path} level={level} hasBorder={false} />
          </>
        } sx={{ fontWeight: 'bold'}} />
        {nameErrors && <FormHelperText>{nameErrors.message}</FormHelperText>}
      </FormControl>

      <FormControl error={!!descriptionErrors} size={size}>
        <Input {...form.register(`${path}.description`)} placeholder="Prompt" />
        {descriptionErrors && <FormHelperText>{descriptionErrors.message}</FormHelperText>}
      </FormControl>

      <TypeSpecificForm path={path} typeValue={typeValue} level={level} />
    </Stack>
  );
}

function TypeSpecificForm({ path, typeValue, level }) {
  const nestedBgColor = bgColorFromLevel(level);
  return <>
    {typeValue === 'enum' && <>
      <Card size="sm" sx={{ backgroundColor: nestedBgColor }}>
        <Typography level="title-sm">Choices</Typography>
        <EnumChoicesForm path={`${path}.value_enum.choices`} />
      </Card>
    </>}

    {typeValue === 'array' && <ArrayValueForm path={`${path}.value_array`} level={level} />}

    {typeValue === 'object' && <>
      <Card size="sm" sx={{ backgroundColor: nestedBgColor }}>
        <Typography level="title-sm">Subfields</Typography>
        <ParametersForm path={`${path}.value_object.parameters`} size="sm" level={level+1} />
      </Card>
    </>}
  </>;
}

function EnumChoicesForm({ path }) {
  const { fields: choices, append, remove } = useFieldArray({ name: path });

  return <>
    {choices.map((field, index) => <Box key={index}>
      <Divider sx={{ mx: -1.25, mb: 1.5 }} />
      <Box sx={{ display: 'flex', gap: 1 }}>
        <EnumChoiceForm key={field.id} path={`${path}.${index}`} />
        {choices.length > MIN_CHOICES && <RemoveFormElementButton size="sm" onClick={() => remove(index)} />}
      </Box>
    </Box>)}
    {choices.length < MAX_CHOICES && <>
      <Divider sx={{ mx: -1.25, mb: 0.5 }} />
      <Button startDecorator={<Add />} size="sm" variant="outlined" onClick={() => append(newChoice())}>Add Choice</Button>
    </>}
  </>;
}

function EnumChoiceForm({ path }) {
  const form = useFormContext();
  const nameErrors = form.getFieldState(`${path}.value`).error;
  const descriptionErrors = form.getFieldState(`${path}.description`).error;

  return (
    <Stack spacing={1} sx={{ width: '100%', mb: 1 }}>
      <FormControl error={!!nameErrors} size="sm">
        <Input {...form.register(`${path}.value`)} placeholder="Value" sx={{ fontWeight: 'bold'}} />
        {nameErrors && <FormHelperText>{nameErrors.message}</FormHelperText>}
      </FormControl>

      <FormControl error={!!descriptionErrors} size="sm">
        <Input {...form.register(`${path}.description`)} placeholder="Prompt" />
        {descriptionErrors && <FormHelperText>{descriptionErrors.message}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

function ArrayValueForm({ path, level }) {
  const form = useFormContext();
  const itemType = form.watch(`${path}.item.type`);
  const nestedBgColor = bgColorFromLevel(level);

  return (
    <Card size="sm" sx={{ backgroundColor: nestedBgColor }}>
      <Typography level="title-sm">Array of</Typography>
      <DataTypeSelect path={`${path}.item`} level={level+1} hasBorder={true} />
      <TypeSpecificForm path={`${path}.item`} typeValue={itemType} level={level+1} />
    </Card>
  );
}

export function DataTypeSelect({ path, level, hasBorder }) {
  const name = `${path}.type`;
  const form = useFormContext();          
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { ref, value, onChange: formOnChange, onBlur}, fieldState: { error } }) => {
        function onChange(value) {
          if (form.getValues(`${path}.value_${value}`) == null) {
            form.setValue(`${path}.value_${value}`, newValue(value));
          }
          return formOnChange(value);
        }

        return (
          <Select
            ref={ref}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            onBlur={onBlur}
            error={error == null ? 'false' : 'true'}
            placeholder="Data type"
            variant={hasBorder ? "outlined": "plain"}
            slotProps={{
              listbox: {
                variant: 'outlined',
              },
            }}
            size={level > 0 ? "sm" : "md"}
            sx={hasBorder ? {} : { mr: -1, '&:hover': { bgcolor: 'transparent' } }}
          >            
            <Option value="string">String</Option>
            <Option value="number">Number</Option>
            <Option value="boolean">Boolean</Option>
            {level + 1 < MAX_NESTED_LEVELS && <>
              <Option value="enum">Choices</Option>
              <Option value="array">Array</Option>
              <Option value="object">Object</Option>
            </>}
          </Select>
        );
      }}
    />
  );
}

function bgColorFromLevel(level) {
  return level % 2 == 0 ? 'rgba(0, 0, 0, 0.02)': level % 3 == 1 ? 'rgba(0, 0, 0, 0.035)' : 'white';
}
