
export function fromAuthErrorCode(errorCode) {
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Invalid email';
    case 'auth/invalid-credential':
      return 'Invalid email or password';
    case 'auth/weak-password':
      return 'Password need to be at least 6 characters long';
    case 'auth/email-already-in-use':
      return 'Account with this email already exists';
    default:
      console.error(errorCode);
      return 'An unexpected error occurred';
  }
}
