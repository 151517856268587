import { Box, Card, FormLabel, FormControl, FormHelperText, Input, Badge, Stack, Typography, Button, Tabs, TabList, TabPanel } from '@mui/joy';
import Tab, { tabClasses } from '@mui/joy/Tab';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from "react-hook-form";
import { FormButtons } from '@/components/FormButtons';
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams } from "react-router-dom";
import { callTemplateSchema } from '../schema';
import { AgendaPointsForm } from './AgendaPointsForm';
import { DataExtractionForm } from './DataExtractionForm';

export function CallTemplateForm({ data, save, saveResult }) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(callTemplateSchema)
  });

  const errors = form.formState.errors;

  async function onSubmit(data) {
    await save(data);
    navigate(`/p/${projectId}/playbooks`);
  }  

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            pt: 2,
            pb: 3,
          }}
        >
          <Stack spacing={2}>
            <FormControl error={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...form.register("name")} placeholder="Name" />
              {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
            </FormControl>
            <FormControl error={!!errors.description} sx={{ mb: 1 }}>
            <FormLabel>Description</FormLabel>
              <Input {...form.register('description')} placeholder="Description" />
              {errors.description && <FormHelperText>{errors.description.message}</FormHelperText>}
            </FormControl>
          </Stack>

          <Tabs aria-label="Basic tabs" defaultValue={0} variant="outlined" sx={{ borderRadius: 'sm' }}>
            <TabList sx={{
              [`& .${tabClasses.root}`]: {
                fontSize: 'sm',
                fontWeight: 'lg',
                [`&[aria-selected="true"]`]: {
                  color: 'primary.500',
                  bgcolor: 'background.surface',
                },
              },
            }}>
              <Tab sx={{ 'border-top-left-radius': '6px'}} color={'agenda' in form.formState.errors ? 'danger' : ''}>Agenda</Tab>
              <Tab color={'data_extraction' in form.formState.errors ? 'danger' : ''}>Data Extraction</Tab>              
            </TabList>
            <TabPanel value={0}>                                            
              <Typography level="body-sm" sx={{mb: 2}}>Describe points that needs to be covered during the call</Typography>
              <AgendaPointsForm />                            
            </TabPanel>
            <TabPanel value={1}>
              <Typography level="body-sm"  sx={{mb: 2}}>Describe which data should be extracted after the call</Typography>            
              <DataExtractionForm />              
            </TabPanel>           
          </Tabs>

        </Stack>
        <FormButtons isSaving={saveResult.isLoading} />
      </form >
    </FormProvider>
  );
}

