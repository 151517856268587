import {FormControl, FormHelperText, FormLabel, Input} from "@mui/joy";
import {useFormContext} from "react-hook-form";

export function FormInput({ name, label, type, floatingLabel }) {
  const form = useFormContext();
  const errors = form.formState.errors;

  return (
    <FormControl error={!!errors[name]}>
      <FormLabel>{label}</FormLabel>
      <Input {...form.register(name)} type={type || "text"} />
      {errors[name] && <FormHelperText>{errors[name].message}</FormHelperText>}
    </FormControl>
  );
}