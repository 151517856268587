import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/joy';

export function FormButtons({ isSaving }) {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Button type="submit" loading={isSaving}>Save</Button>
      <Button variant="outlined" onClick={() => navigate('..')}>Cancel</Button>
    </Box>
  );
}