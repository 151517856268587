import {baseApi} from "@/utils/api.js";

const activeUserApi = baseApi.enhanceEndpoints({
  addTagTypes: ['ActiveUser'],
}).injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: ['ActiveUser'],
    }),
    createProfile: builder.mutation({
      query: ({ data }) => ({
        url: '/users/me',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ActiveUser', 'Projects'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useCreateProfileMutation,
} = activeUserApi;
