import { useParams } from "react-router-dom";
import { CrudTable } from '@/components/CrudTable';
import { CallStatus } from '@/components/features/CallStatus';
import { useGetCallsQuery } from '@/features/calls/api';
import { created } from '@/utils/columns';


export function ListDebugCalls({ templateId }) {
  const { projectId } = useParams();  
  const useQuery = () => useGetCallsQuery({ projectId, template_id: templateId, meeting_url: 'https://debug.getraise.ai/' });

  const options = {
    names: {
      singular: 'call',
      plural: 'calls',
    },    

    columns: [      
      { label: 'ID', getter: (row) => row.id },
      { label: 'Status', getter: (row) => <CallStatus status={row.status} /> },
      created(),      
    ],

    actions: {
      list: useQuery,      
      get: ':id',      
    },
  };

  return <CrudTable {...options} />;
}
