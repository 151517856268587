import * as yup from 'yup';

export const jobTitles = [
  {value: 'sales_representative', label: 'Sales Representative'},
  {value: 'sales_manager', label: 'Sales Manager'},
  {value: 'sales_enablement', label: 'Sales Enablement'},
  {value: 'rev_ops', label: 'Revenue Operations'},
  {value: 'executive', label: 'Executive'},
];

export const needHelpWith = [
  {value: 'crm_management', label: 'Filling out CRM data'},
  {value: 'call_preparation', label: 'Call preparation'},
  {value: 'real_time_help', label: 'Realtime help during calls'},
  {value: 'email_follow_ups', label: 'Writing follow up emails'},
];

export const crms = [
  {value: 'hubspot', label: 'HubSpot'},
  {value: 'salesforce', label: 'Salesforce'},
  {value: 'pipedrive', label: 'Pipedrive'},
  {value: 'copper', label: 'Copper'},
  {value: 'zoho', label: 'Zoho'},
  {value: 'none', label: 'I don\'t use CRM'},
  {value: 'other', label: 'Other'},
];

export function newProfile() {
  return {
    company_name: '',
    job_title: 'sales_representative',
    crm: 'none',
    need_help_with: [],
  };
}

export const profileSchema = yup.object({
  company_name: yup.string().required('Company name is required'),
  job_title: yup.string().oneOf(values(jobTitles)).required('Job title is required'),
  crm: yup.string().oneOf(values(crms)),
  need_help_with: yup.array().of(yup.string().oneOf(values(needHelpWith))),
});

function values(options) {
  return options.map(option => option.value);
}
