export function created() {
  return {
    label: 'Created',
    getter: (row) => formatDate(row.created)
  };
}

export function formatDate(date) {
  return new Date(date).toLocaleString();
}
