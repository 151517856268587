import {useTheme} from "@mui/joy";

export function IconLogo({size}) {
  const theme = useTheme();
  return (
    <svg width={size ?? "51px"} viewBox="0 0 51 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51 19.002C51 27.8143 44.7406 35.6093 36.1159 37.5373C35.9879 37.5684 35.8553 37.5813 35.7272 37.5813C34.918 37.5813 34.1887 37.0267 34.0034 36.208C33.7915 35.2572 34.3882 34.3155 35.343 34.1039C42.3674 32.5325 47.464 26.1808 47.464 19.002C47.464 10.4673 40.4883 3.52144 31.9169 3.52144C26.2854 3.52144 21.1044 6.54973 18.3504 11.4445C18.3458 11.449 18.3458 11.4536 18.3458 11.4575C18.0585 12.2322 17.2493 12.7253 16.401 12.5843C15.8134 12.4872 15.2076 12.4393 14.6064 12.4393C8.5017 12.4393 3.53713 17.382 3.53713 23.4612C3.53713 29.5352 8.50113 34.4786 14.6064 34.4786C20.6363 34.4786 25.5561 29.6458 25.6666 23.6683L23.8941 25.4332C23.1999 26.1244 22.0819 26.1199 21.3922 25.4332C20.7026 24.7465 20.7026 23.6327 21.3922 22.946L26.1885 18.1658C26.52 17.8357 26.9711 17.6506 27.4397 17.6506C27.9083 17.6506 28.3594 17.8357 28.6909 18.1658L33.4872 22.946C34.1813 23.6327 34.1813 24.7465 33.4872 25.4332C33.1426 25.7763 32.6916 25.9484 32.2405 25.9484C31.7855 25.9484 31.3344 25.7768 30.9893 25.4332L29.2032 23.6592C29.0972 31.5823 22.5902 38 14.6064 38C6.55123 37.9994 0 31.4762 0 23.4606C0 15.441 6.55123 8.91785 14.6053 8.91785C14.9764 8.91785 15.3436 8.93082 15.7148 8.96185C19.1896 3.40689 25.2943 0 31.9164 0C42.4416 0 51 8.52175 51 19.002Z"
        fill="#6366F1"/>
    </svg>
  );
}