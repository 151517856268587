import * as yup from 'yup';

export function newCompany() {
  return {
    name: '',
  };
}

export const companySchema = yup.object({
  name: yup.string().required('Company name is required'),
});
