import { FormLabel, FormControl, FormHelperText, Input, Select, MenuItem, Option, Stack } from '@mui/joy';
import { FormButtons } from '@/components/FormButtons';
import { useParams, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useFormContext, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { callSchema } from '../schema';
import { useGetCallTemplatesQuery } from '../../callTemplates/api';
import {useGetCompaniesQuery} from "@/features/companies/api.js";
import {FormSelectEntity} from "@/components/forms/FormSelectEntity.jsx";

export function CallForm({ data, save, saveResult }) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: data,
    resolver: yupResolver(callSchema),
  });

  const errors = form.formState.errors;

  async function onSubmit(data) {
    await save(data);
    navigate(`/p/${projectId}/calls`);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            pt: 2,
            pb: 3,
          }}
        >
          <Stack spacing={3}>
            <FormControl error={!!errors.meeting_url}>
              <FormLabel>Meeting URL</FormLabel>
              <Input {...form.register("meeting_url")} placeholder="e.g. http://zoom.us/j/..." />
              {errors.meeting_url && <FormHelperText>{errors.meeting_url.message}</FormHelperText>}
            </FormControl>

            <FormControl error={!!errors.template_id}>
              <FormLabel>Call type</FormLabel>
              <CallTemplateSelect />
              {errors.template_id && <FormHelperText>{errors.template_id.message}</FormHelperText>}
            </FormControl>

            <FormControl error={!!errors.company_id}>
              <FormLabel>Company</FormLabel>
              <CompanySelect />
              {errors.company_id && <FormHelperText>{errors.company_id.message}</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>
        <FormButtons isSaving={saveResult.isLoading} />
      </form >
    </FormProvider>
  );
}

export function CallTemplateSelect() {
  return <FormSelectEntity name="template_id" placeholder={"Select call playbook"} useQuery={useGetCallTemplatesQuery} />;
}

export function CompanySelect() {
  return <FormSelectEntity name="company_id" nullable={true} placeholder={"Select company"} useQuery={useGetCompaniesQuery} />;
}
