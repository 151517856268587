import {WelcomePage} from "@/features/onboarding/pages/WelcomePage.jsx";
import {RequireAuth} from "@/features/auth/components/RequireAuth.jsx";
import {DefaultProjectPage} from "@/features/onboarding/pages/DefaultProjectPage.jsx";

export const onboardingRoutes = {
  element: <RequireAuth/>,
  children: [{
    path: 'default-project',
    element: <DefaultProjectPage/>,
  }, {
    path: 'onboarding',
    element: <WelcomePage/>,
  }],
};
