import {extendCrudApiByProject} from '@/utils/api.js';

const companiesApi = extendCrudApiByProject({
  entityNameSingular: 'Company',
  entityNamePlural: 'Companies',
  entityPath: 'companies',
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useCreateCompanyMutation,
} = companiesApi;
