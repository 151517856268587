import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { PageHeader } from '@/components/PageHeader';
import { CallTemplateForm } from '../components/CallTemplateForm';
import { useParams } from "react-router-dom";
import { useGetCallTemplateQuery, useUpdateCallTemplateMutation } from '../api';

export function UpdateCallTemplatePage() {  
  const { projectId, id } = useParams();
  const { isFetching, data } = useGetCallTemplateQuery({ projectId, id });    

  const [saveCallTemplate, saveResult] = useUpdateCallTemplateMutation();

  async function save(data) {
    await saveCallTemplate({ projectId, id, data });
  }

  return (
    <DrawerLayout title="Update Playbook">            
      {isFetching ? 'Loading...' : <CallTemplateForm data={data.data} save={save} saveResult={saveResult} />}
    </DrawerLayout>
  );
}

