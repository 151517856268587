import { DrawerLayout } from '@/components/layouts/DrawerLayout.jsx';
import { CompanyForm } from '../components/CompanyForm';
import { useParams } from "react-router-dom";
import { useCreateCompanyMutation } from '../api';
import { newCompany } from '../schema';

export function CreateCompanyPage() {
  const { projectId } = useParams();

  const data = newCompany();
  const [saveCompany, saveResult] = useCreateCompanyMutation();

  async function save(data) {
    await saveCompany({ projectId, data });
  }

  return (
    <DrawerLayout title="Create Company">
      <CompanyForm data={data} save={save} saveResult={saveResult} />
    </DrawerLayout>
  );
}
