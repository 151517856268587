import * as yup from 'yup';

export function newCall() {
  return {    
    meeting_url: '',    
    template_id: '',
    company_id: '',
  };
}

export const callSchema = yup.object({
  meeting_url: yup.string().required('Meeting URL is required').url('Must be a valid URL'),  
  template_id: yup.string().required('Playbook is required'),
  company_id: yup.string().nullable(),
});
