import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/joy";

export function ExtraAction({ to, icon }) {
  const navigate = useNavigate();

  function click(e) {
    e.stopPropagation();
    navigate(to);
  }

  return (    
    <IconButton size="sm" variant="plain" onClick={click}>{icon}</IconButton>    
  );
}