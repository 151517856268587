import { useParams, Outlet } from "react-router-dom";
import { ProjectLayout } from '@/components/layouts/ProjectLayout.jsx';
import { PageHeader } from '@/components/PageHeader';
import { CrudTable } from '@/components/CrudTable';
import { useGetCallTemplatesQuery, useDeleteCallTemplateMutation } from '../api';
import { useCrudProjectMutation } from '@/hooks/useCrudProjectMutation';
import { created } from '@/utils/columns';
import BugReportIcon from '@mui/icons-material/BugReport';

export function CallTemplatesPage() {
  const { projectId } = useParams();
  const useQuery = () => useGetCallTemplatesQuery({ projectId });
  const useDelete = useCrudProjectMutation(projectId, useDeleteCallTemplateMutation);
  
  const options = {
    names: {
      singular: 'playbook',
      plural: 'playbooks',
    },

    columns: [
      { label: 'Name', getter: (row) => row.name }, 
      created(),    
    ],

    actions: {
      list: useQuery,
      create: 'create',
      get: ':id',
      delete: useDelete,
      extra: [{ type: 'debug', icon: <BugReportIcon /> }],
    },
  };

  return (
    <ProjectLayout>
      <PageHeader title="Playbooks" />
      <CrudTable {...options} />      
      <Outlet />
    </ProjectLayout>    
  );
}
