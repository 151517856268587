export function addTranscriptWebhookPayload({ botId, skipGrouping, role, participantName, startTime, endTime, text }) {
  const words = text.split(' ');
  const wordLength = (endTime - startTime) / words.length;
  return {
    "event": "bot.transcription",
    "data": {      
      "bot_id": botId,      
      "transcript": {
        "speaker": participantName,
        "speaker_id": role === 'Host' ? 1 : 2,
        "speaker_role": role,
        "skip_grouping": !!skipGrouping,
        "words": words.map((word, index) => ({
          "text": word,
          "start_time": startTime + index * wordLength,
          "end_time": startTime + (index + 1) * wordLength,
        })),
      }
    }
  };
}