import  { CompaniesPage } from './pages/CompaniesPage'
import  { CreateCompanyPage } from './pages/CreateCompanyPage'
import  { UpdateCompanyPage } from './pages/UpdateCompanyPage'

export const companiesRoutes = {
  path: 'companies',
  children: [{
    path: '',
    element: <CompaniesPage />,
    children: [{
      path: 'create',
      element: <CreateCompanyPage />,
    }, {
      path: ':id',
      element: <UpdateCompanyPage />,
    }],
  }],
};
