export function statusCodeToMessage(statusCode) {
  switch (statusCode) {
    case 'FETCH_ERROR':
      return 'Unable to connect to the server. Try again later.';
    case 400:
      return 'Bad request. Please check your input.';
    case 401:
      return 'You are not authenticated. Please sign in.';
    case 403:
      return 'You do not have permission to access this resource.';
    case 404:
      return 'The resource you are looking for does not exist.';
    case 500:
      return 'Internal server error. Please try again later.';
    default:
      return 'Unknown error. Please try again later.';
  }
}