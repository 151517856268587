import  { CallTemplatesPage } from './pages/CallTemplatesPage'
import  { CreateCallTemplatePage } from './pages/CreateCallTemplatePage'
import  { UpdateCallTemplatePage } from './pages/UpdateCallTemplatePage'
import  { DebugCallTemplatePage } from '../debug/pages/DebugCallTemplatePage'
import  { DebugCallTemplateCallPage } from '../debug/pages/DebugCallTemplateCallPage'

export const callTemplatesRoutes = {
  path: 'playbooks',
  children: [{
    path: '',
    element: <CallTemplatesPage />,
    children: [{
      path: 'create',
      element: <CreateCallTemplatePage />,
    }, {
      path: ':id',
      element: <UpdateCallTemplatePage />,
    }],
  }, {
    path: ':id/debug',
    element: <DebugCallTemplatePage />,
    children: [{
      path: ':callId',
      element: <DebugCallTemplateCallPage />,
    }],
  }],        
};
