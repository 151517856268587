import { CenteredLayout } from '@/components/layouts/CenteredLayout.jsx';
import { CrudTable } from '@/components/CrudTable';
import { useGetProjectsQuery } from '../api';
import { PageHeader } from '@/components/PageHeader';
import { created } from '@/utils/columns';
import {ActiveUser} from "@/components/ActiveUser.jsx";
import {Box} from "@mui/joy";

const options = {
  names: {
    singular: 'organization',
    plural: 'organizations',
  },

  columns: [
    { label: 'Name', getter: (row) => row.name }, 
    created(),    
  ],

  actions: {
    list: useGetProjectsQuery,
    create: 'create',      
    get: '/p/:id'
  },
};

export function ProjectsPage() {        
  return (
    <CenteredLayout>
      <PageHeader title="Organizations" />
      <CrudTable {...options} />
      <Box sx={{ p: 2, width: '240px', position: 'absolute', bottom: 0, left: 0 }}>
        <ActiveUser />
      </Box>
    </CenteredLayout>
  );
}