import {Alert} from "@mui/joy";
import {statusCodeToMessage} from "@/utils/errors.js";

export function ApiError({ error }) {
  return (
    <Alert color="danger">
      {statusCodeToMessage(error.status)}
    </Alert>
  );
}

