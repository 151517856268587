import * as yup from 'yup';
import {yupPasswordField} from "@/utils/passwords.js";

export function newSignIn() {
  return {
    email: '',
    password: '',
  };
}

export function newSignUp() {
  return {
    email: '',
    password: '',
    agreement: false,
  };
}

export function newForgottenPassword() {
  return {
    email: '',
  };
}

export const signInSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup.string().required('Password is required'),
});


export const signUpSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yupPasswordField('password'),
  agreement: yup.boolean().isTrue('You must agree to the terms of use and privacy policy'),
});

export const forgottenPasswordSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is invalid'),
});
