import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "@/features/auth/firebase.js";
import {Navigate, Outlet} from "react-router-dom";

export function RequireNoAuth() {
  const [user] = useAuthState(auth);
  if (user !== null) {
    return <Navigate to='/' />
  } else {
    return <Outlet />;
  }
}
