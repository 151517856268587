import { Drawer, Divider, Typography, Box, ModalClose } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

export function DrawerLayout({ title, headerButtons, wide, children }) {
  const navigate = useNavigate();

  return (
    <Drawer open anchor="right" size="md" onClose={() => navigate('..')} sx={{ '.MuiDrawer-content': { minWidth: wide ? '1000px' : '750px', width: wide ? '75%' : undefined}}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 3, my: 2, mr: 7, gap: 1 }}>
        <Typography level="h3" sx={{ width: '100%' }}>{ title }</Typography>
        {headerButtons && <>{headerButtons}<Divider orientation="vertical" variant="middle" /></>}
      </Box>
      <ModalClose sx={{ mr: 1, mt: 1}} />                  
      <Box sx={{ p: 3, pt: 0 }}>
        <Divider sx={{ mx: -3, mb: 1 }} />
        { children }
      </Box>
    </Drawer>
  );
}
