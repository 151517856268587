import { Alert, CircularProgress, Stack, Typography } from '@mui/joy';

export function ContentGenerationInProgress({ title }) {  
  return (
    <Alert variant="soft">
      <Stack direction="row" gap={1.5}>      
      <CircularProgress size="sm" />
        <Typography level="body-sm" >{ title } in progress...</Typography>        
      </Stack>
    </Alert>
  );  
}