import {validatePassword} from "firebase/auth";
import {auth} from "@/features/auth/firebase.js";
import * as yup from "yup";

export function yupPasswordField(path) {
  return yup.string().required('Password is required')
    .test(async function  (value) {
      const status = await validatePassword(auth, value);
      if (!status.isValid) {
        return this.createError({message: getPasswordErrorMessage(status), path});
      }
      return true;
    });
}

const errors = {
  containsNumericCharacter: 'Password must contain at least one number',
  containsLowercaseLetter: 'Password must contain at least one lowercase letter',
  containsUppercaseLetter: 'Password must contain at least one uppercase letter',
  containsNonAlphanumericCharacter: 'Password must contain at least one special character',
}

function getPasswordErrorMessage(status) {
  for (const [key, message] of Object.entries(errors)) {
    if (!status[key] && status.passwordPolicy.customStrengthOptions[key]) {
      return message;
    }
  }
  if (!status.meetsMaxPasswordLength) {
    return `Password must be less than ${status.passwordPolicy.customStrengthOptions.maxPasswordLength} characters long`;
  }
  if (!status.meetsMinPasswordLength) {
    return `Password must be at least ${status.passwordPolicy.customStrengthOptions.minPasswordLength} characters long`;
  }
  return 'Password is too weak';
}
