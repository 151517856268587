import {Divider, Option, Typography} from '@mui/joy';
import Select, {selectClasses} from '@mui/joy/Select';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetProjectsQuery} from '../features/projects/api';
import {IconLogo} from "@/components/IconLogo.jsx";
import {KeyboardArrowDown} from "@mui/icons-material";

export function ProjectSwitcher() {
  const navigate = useNavigate();
  const {projectId} = useParams();

  // TODO: Loading
  const {isFetching, data: projects} = useGetProjectsQuery();
  const disabled = projects?.data?.length === 1;

  function switchProject(projectId) {
    // TODO: navigate to the selected page
    navigate(`/p/${projectId}`);
  }

  return (
    <>
      <Select
        value={projectId}
        disabled={disabled}
        onChange={(_, value) => switchProject(value)}
        startDecorator={<IconLogo size={35}/>}
        renderValue={(value) => <Typography level="title-lg" sx={{pl: 0.5}}>{value.label}</Typography>}
        indicator={disabled ? null : <KeyboardArrowDown/>}
        size={"sm"}
        slotProps={{
          listbox: {
            sx: {},
          },
        }}
        sx={{
          background: 'transparent',
          border: 'none',
          [`&.${selectClasses.root}`]: {
            p: 2,
            m: -2,
            boxShadow: "none",
            border: "none",
            borderRadius: "none",
          },
          [`& .${selectClasses.indicator}`]: {
            transition: '0.2s',
            [`&.${selectClasses.expanded}`]: {
              transform: 'rotate(-180deg)',
            },
          },
        }}
      >
        {projects?.data?.map((project) => <Option key={project.id} value={project.id} sx={{px: 2, textOverflow: 'ellipsis', width: '240px'}}>{project.name}</Option>)}
      </Select>
    </>
  );
}