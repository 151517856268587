import {extendCrudApiByProject} from '@/utils/api.js';

const callTemplatesApi = extendCrudApiByProject({
  entityNameSingular: 'CallTemplate',
  entityNamePlural: 'CallTemplates',
  entityPath: 'call-templates',
});

export const {
  useGetCallTemplatesQuery,
  useGetCallTemplateQuery,
  useUpdateCallTemplateMutation,
  useDeleteCallTemplateMutation,
  useCreateCallTemplateMutation,
} = callTemplatesApi;
