import { ProjectsPage } from "./pages/ProjectsPage";
import { CreateProjectPage } from "./pages/CreateProjectPage";
import {RequireAuth} from "@/features/auth/components/RequireAuth.jsx";

export const projectsRoutes = {
  path: "/admin/organizations",
  element: <RequireAuth />,
  children: [{
    path: "",
    element: <ProjectsPage />,
  }, {
    path: "create",
    element: <CreateProjectPage />,
  }]
};
