import {SignInPage} from "@/features/auth/pages/SignInPage.jsx";
import {SignUpPage} from "@/features/auth/pages/SignUpPage.jsx";
import {ForgottenPasswordPage} from "@/features/auth/pages/ForgottenPasswordPage.jsx";
import {RequireNoAuth} from "@/features/auth/components/RequireNoAuth.jsx";

export const authRoutes = {
  element: <RequireNoAuth />,
  children: [
    {
      path: "/sign-in",
      element: <SignInPage />,
    },
    {
      path: "/sign-up",
      element: <SignUpPage />,
    },
    {
      path: "/forgotten-password",
      element: <ForgottenPasswordPage />,
    }
  ]
};
