import Typography from '@mui/joy/Typography';
import { CenteredLayout } from '../../../components/layouts/CenteredLayout.jsx';
import { PageHeader } from '../../../components/PageHeader';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormLabel, Input, FormHelperText, Stack } from '@mui/joy';
import { newProject, projectSchema } from '../schema';
import { useCreateProjectMutation } from '../api';
import { useNavigate } from 'react-router-dom';

export function CreateProjectPage() {
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: newProject(),
    resolver: yupResolver(projectSchema)
  });

  const [saveProject, saveResult] = useCreateProjectMutation();

  async function onSubmit(data) {    
    await saveProject({ data });
    navigate(`/projects`);
  }

  const errors = form.formState.errors;

  return (
    <CenteredLayout>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>          
          <PageHeader title="Create Project" />
          <Stack spacing={1} sx={{ mt: 2, mb: 3 }}>
            <FormControl error={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...form.register("name")} placeholder="Name" />
              {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
            </FormControl>
          </Stack>
          <Button type="submit" loading={saveResult.isLoading}>Save</Button>
        </form>
      </FormProvider>
    </CenteredLayout>
  );
}